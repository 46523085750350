@use '../../../styles/bootstrap';

.inputContainer {
  margin-bottom: 16px;
}

.messageInput {
  height: 200px;
}

@include bootstrap.media-breakpoint-down(sm) {
  .inputContainer {
    margin-bottom: 16px;
  }
}

.errorText {
  margin-top: 8px;
  font-size: 0.875rem;
}
