.header {
  grid-gap: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.title {
  color: var(--primary-black);
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 120%;
  letter-spacing: 0.28px;
  text-align: center;
}

.subtitle {
  color: #ff485c;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 120%;
  letter-spacing: 0.28px;
  text-align: center;
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emailGroup {
  margin-bottom: 16px;
  width: 100%;
}

.disclaimer {
  display: flex;
  flex: 1;
  grid-gap: 8px;
  justify-content: flex-start;
  align-self: flex-start;
  color: #737377;
  font-weight: 400;
  font-size: 12px;
}

.members {
  display: flex;
  align-items: center;
  margin: 8px 0 16px;
  width: 100%;
  text-align: left;
}

.membersHint {
  margin-left: 8px;
  font-size: 14px;
  line-height: 20px;
}
