.container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.title {
  margin-bottom: 16px;
  color: var(--primary-black);
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: 0.28px;
  text-align: center;
}

.button {
  position: sticky;
  bottom: 16px;
}

.submitButton {
  position: sticky;
  bottom: 16px;
  align-self: center;
}
