.imageWrapper {
  position: relative;
  margin: -16px 0 -24px -24px;
  background: transparent;
  aspect-ratio: 375/383;
  width: calc(100% + 48px);
  max-width: 540px;
  height: 100%;
}

.content {
  flex: 1;
  margin: 24px -24px 0 -24px;
  background: transparent;
}

.title,
.subtitle {
  margin: 24px 24px 8px 24px;
}

.title {
  font-size: 28px;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: 0.18px;
}

.progressContainer {
  display: flex;
  position: relative;
  align-items: center;
  margin: 0 24px;
  border-radius: 24px;
  background: rgba(120, 120, 128, 0.16);
  height: 32px;
  overflow: hidden;
  text-align: center;
}

.progressFill {
  position: absolute;
  border-radius: 24px;
  background: linear-gradient(
    180deg,
    rgba(255, 82, 44, 1) 0%,
    rgba(255, 60, 139, 1) 100%
  );
  height: 100%;
}

.additionalBlock {
  display: flex;
  justify-content: left;
  gap: 16px;
  margin: 16px 24px 24px;
  padding-left: 6px;
  > span {
    font-size: 14px;
  }
}

.loader {
  display: block;
  animation: loader-first-1 0.8s infinite linear alternate,
    loader-first-2 1.6s infinite linear;
  border: 2px solid #ff485c;
  border-radius: 50%;
  aspect-ratio: 1;
  width: 20px;
}
@keyframes loader-first-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }
  12.5% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
  }
  25% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }
  50% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  62.5% {
    clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  75% {
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  100% {
    clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
  }
}

@keyframes loader-first-2 {
  0% {
    transform: scaleY(1) rotate(0deg);
  }
  49.99% {
    transform: scaleY(1) rotate(135deg);
  }
  50% {
    transform: scaleY(-1) rotate(0deg);
  }
  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}

.bottomContent {
  display: flex;
  flex-direction: column;
  margin: 0 -24px -16px;
}
