.title {
  color: var(--local-purple);
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: 0.28px;
  text-align: center;
}

.subtitle {
  margin-top: 16px;
  color: var(--local-black);
  font-style: normal;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
}

.imageWrapper {
  display: flex;
  position: relative;
  flex: 1;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
  height: 100%;
}
