.container {
  position: relative;
  box-shadow: 0 0 24px 0 rgba(255, 63, 130, 0.2);
  border-radius: 16px;
  background: var(--white);
  width: 100%;
  min-height: 355px;
  max-height: 479px;
}

.subtext {
  position: absolute;
  bottom: 0;
  padding: 16px;
  color: #737377;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}
