@use '../../../../styles/bootstrap';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: var(--header-height);
}

.leftContainer {
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  margin-right: 16px;
}

.title {
  flex: 1;
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
}

.rightContainer {
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  margin-left: 16px;
}

@include bootstrap.media-breakpoint-between(md, lg) {
  .container {
    height: var(--tablet-header-height);
  }
}

@include bootstrap.media-breakpoint-down(md) {
  .container {
    height: var(--mobile-header-height);
  }
}
