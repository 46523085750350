.form {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.control {
  grid-gap: 16px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  border: 1px solid var(--gray-middle);
  border-radius: 16px;
  background: var(--white);
  padding: 16px;
  width: 327px !important;
  height: 72px;
  text-align: center;

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    width: auto;
    height: auto;
  }
}

.errorText {
  display: flex;
  justify-content: center;
  margin: 0;
  color: var(--accent-gradient-start);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
}

.buttonGroup {
  grid-gap: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
}
