.container {
  display: flex;
  flex: 0 0 48%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}
.label {
  font-size: 14px;
  line-height: 140%;
  text-align: left;
}
.progressWrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 4px;
}
.progress {
  --color-value: linear-gradient(
    100deg,
    #ff6f66 7.22%,
    #ffeb3b 38.94%,
    #5bc083 81.86%
  ) !important;
  --progress-radius: 8px !important;
  margin-right: 8px;
  border-radius: 8px;
  width: 100%;
  height: 8px;
}
.progressPercent {
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
}