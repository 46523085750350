.sliderContainer {
  margin: 0 -12px;
}

.review {
  grid-gap: 8px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  margin: auto;
  border-radius: 16px;
  background: var(--white);
  padding: 16px;
  width: 335px !important;
  min-height: 162px;
}

.reviewSubtitle {
  color: var(--gray-dark);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
}

.reviewContainer {
  grid-gap: 8px;
  display: flex;
  align-items: center;
  align-self: stretch;
}

.reviewAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: var(--gray-middle);
  padding: 7px 7.5px;
  color: var(--primary-black);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
}

.reviewStars {
  grid-gap: 2px;
  display: flex;
  align-items: center;
}

.reviewContent {
  color: var(--primary-black);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
}
