.title {
  margin: 32px 0 24px;
  font-weight: 700;
  font-size: var(--mg-h1-fs);
}

.total {
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  margin-bottom: 20px;
  text-align: left;
}

.verticalLine {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: var(--mg-color-primary-outline);
  width: 1px;
  height: 100%;
}

.item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--color-pastel);
}

.highlighted {
  display: block;
  color: var(--local-black);
}
