.imageWrapper {
  position: relative;
  margin: -16px 0 -24px -24px;
  aspect-ratio: 375/383;
  width: calc(100% + 48px);
  max-width: 540px;
  height: 100%;
}

.content {
  flex: 1;
  margin: 24px -24px 0 -24px;
}

.title,
.subtitle {
  margin: 24px 24px 8px 24px;
}

.title {
  font-size: var(--mg-h1-fs);
}

.subtitle {
  font-weight: 400;
  font-size: var(--mg-body1-fs);
  letter-spacing: 0.18px;
}
