.container {
  align-items: center;
}

.disclaimerContainer {
  display: flex;
  grid-gap: 12px;
  margin-bottom: auto;
  border-radius: 16px;
  background: var(--gray-light);
  padding: 12px 16px;
  max-width: 327px;
  font-size: 14px;
}
