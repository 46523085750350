@use '../../../../../../styles/bootstrap';
@use '../../../../../../styles/utils';

.container {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.tabs {
  align-self: center;
  margin-bottom: 24px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.controlGroup {
  width: 100%;
  max-width: 335px;
}

.controlGroup + .controlGroup {
  margin-top: 24px;
}

.subText {
  align-self: stretch;
  margin-top: 8px;
  color: var(--local-grey);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
}

.errorText {
  color: var(--local-red);
}

.submitButton {
  position: fixed;
  bottom: 16px;
  width: 100%;
  max-width: 327px;
}

@include bootstrap.media-breakpoint-down(sm) {
  .form {
    margin-top: 0;
  }

  .controlGroup {
    max-width: 342px;
  }

  .submitButton {
    margin-top: 40px;
  }
}

.tooltip {
  grid-gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 32px;
  border: 1px solid var(--accent-green);
  border-radius: 16px;
  background: var(--gray-light);
  padding: 12px;

  .title,
  .subTitle {
    color: var(--primary-black);
    font-style: normal;
    font-size: 12px;
    line-height: 140%;
  }

  .title {
    font-weight: 700;
  }
  .subTitle {
    font-weight: 400;
  }
}
