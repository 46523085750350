:root,
[data-theme^="variant"] {
  /*typography tokens */
  --mg-primary-ff: "Inter", sans-serif;
  --mg-secondary-ff: "Inter", sans-serif;
  --mg-fw-400: 400;
  --mg-fw-700: 700;
  --mg-h1-fs: 28px;
  --mg-h2-fs: 20px;
  --mg-body1-fs: 16px;
  --mg-body2-fs: 14px;
  --mg-body3-fs: 12px;
  --mg-body4-fs: 10px;
  --mg-ls-1: 1%;
  --mg-lh-140: 140%;
  --mg-lh-120: 120%;

  --rounded-radius-4: 4px;
  --rounded-radius-8: 8px;
  --rounded-radius-16: 16px;
  --rounded-radius-32: 32px;

  --mg-grid-gap-8: 8px;
  --mg-grid-gap-12: 12px;
  --mg-grid-gap-16: 16px;
  --mg-grid-gap-24: 24px;

  --mg-color-primary: linear-gradient(272deg, #ff522c 2.82%, #ff3c8b 97.18%);
  --mg-color-primary-container: #faf9ff;
  --mg-color-primary-outline: #e5e5e5;
  --mg-color-primary-variant: #ffd4ca;
  --mg-color-on-primary: #ffffff;

  --mg-color-secondary: #302c28;
  --mg-color-secondary-container-variant: #feeedb;
  --mg-color-on-secondary-container-variant: #302c28;
  --mg-color-secondary-variant: #c8c4bb;
  --mg-color-on-secondary: #e5e5e5;

  --mg-color-tertiary: #875ff4;
  --mg-color-on-tertiary: #fff;

  --mg-color-error: #ed827b;
  --mg-color-on-error: #ffffff;
  --mg-color-background: #ffffff;
  --mg-color-on-background: #302c28;
  --mg-color-surface: #fff;
  --mg-color-on-surface: #302c28;
  --mg-color-surface-variant: #e7e4de;
  --mg-color-on-surface-variant: #817b6f;
  --mg-color-outline: #ff485c;
  --mg-color-outline-secondary: #c8c4bb;
  --mg-color-outline-tertiary: #e7e4de;
  --mg-color-disabled: linear-gradient(272deg, #ffd4ca 2.82%, #ffcee2 97.18%);

  /* all elements */
  --rounded-radius-element: var(--rounded-radius-16);

  /*input tokens*/
  --mg-input-padding: 24px 20px;
  --mg-input-fw: var(--mg-fw-400);
  --mg-input-fs: var(--mg-body1-fs);
  --mg-input-ff: var(--mg-primary-ff);
  --mg-input-lh: 1.4;
  --mg-input-border: 0 0 0 1px var(--mg-color-outline-secondary);
  --mg-input-border-focus: 0 0 0 1px var(--mg-color-outline);
  --mg-input-border-invalid: 0 0 0 1px var(--mg-color-error);
  --mg-input-border-radius: var(--rounded-radius-element);
  --mg-input-md-min-height: 72px;

  /*button tokens */
  --mg-bg-button-primary: linear-gradient(
    266.08deg,
    var(--accent-gradient-start) 4.28%,
    var(--accent-gradient-end) 87.63%
  );
  --mg-bg-button-hover: linear-gradient(
    266.08deg,
    var(--accent-gradient-start) 4.28%,
    var(--accent-gradient-end) 87.63%
  );
  --mg-bg-button-secondary: var(--mg-color-on-secondary);
  --mg-color-button-secondary: var(--mg-color-secondary);
  --mg-button-fw: var(--mg-fw-700);
  --mg-button-radius: var(--rounded-radius-element);
  --mg-button-fs: var(--mg-body1-fs);
  --mg-button-sm-fs: var(--mg-body2-fs);
  --mg-button-ff: var(--mg-primary-ff);
  --mg-button-primary-ff: var(--mg-primary-ff);
  --mg-button-secondary-ff: var(--mg-secondary-ff);
  --mg-button-sm-min-height: 40px;
  --mg-button-md-min-height: 64px;
  --mg-button-md-p-y: 19px;
  --mg-button-sm-p-y: 10px;
  --mg-button-md-p-x: 22px;
  --mg-button-sm-p-x: 22px;

  /*  Radio tokens*/
  --mg-bg-radio: var(--mg-color-primary-container);
  --mg-bg-radio-checked: var(--mg-color-primary-container);
  --mg-color-radio-checked: var(--mg-color-on-background);
  --mg-color-radio-label: var(--mg-color-on-background);
  --mg-width-radio-outline: 1px;
  --mg-color-radio-outline: var(--mg-color-primary-outline);
  --mg-color-radio-outline-checked: var(--mg-color-outline);
  --mg-radio-padding: 16px;
  --mg-radio-radius: var(--rounded-radius-element);
  --mg-radio-square-radius: var(--rounded-radius-element);
  --mg-radio-square-gap: 12px;
  --mg-radio-rectangle-gap: 8px;
  --mg-radio-fw: var(--mg-fw-400);
  --mg-radio-fs: 18px;
  --mg-radio-lh: 21px;
  --mg-radio-md-height-rectangle: 80px;
  --mg-radio-md-width-square: 156px;
  --mg-radio-md-height-square: 156px;
  --mg-radio-md-icon-rectangle: 40px;
  --mg-radio-md-icon-square: 72px;
  --mg-radio-md-icon-rectangle-max: 80px;

  /*  Title tokens*/
  --mg-color-title-mark: var(--mg-color-error);
  --mg-bg-title-mark: none;
  --mg-title-fs: 22px;
  --mg-title-ff: var(--mg-secondary-ff);
  --mg-title-fw: 900;
  --mg-title-lh: 30px;
  --mg-title-mb: 24px;
  --mg-title-cutaway-fs: 28px;
  --mg-title-cutaway-ff: var(--mg-secondary-ff);
  --mg-title-cutaway-fw: 700;
  --mg-title-cutaway-lh: 39px;

  /*  Tooltip tokens*/
  --mg-tooltip-fs: var(--mg-body3-fs);
  --mg-tooltip-ff: var(--mg-primary-ff);
  --mg-tooltip-radius: var(--rounded-radius-element);
  --mg-bg-tooltip: var(--mg-color-secondary-container-variant);
  --mg-bg-tooltip-variant: var(--mg-color-primary-container);
  --mg-color-tooltip: var(--mg-color-on-secondary-container-variant);
  --mg-tooltip-padding: 12px;
  --mg-tooltip-title-fw: var(--mg-fw-700);
  --mg-tooltip-title-gap: 4px;
  --mg-tooltip-image-gap: 8px;
  --mg-tooltip-outline: none;

  /* Checkbox tokens */
  --mg-bg-checkbox: var(--mg-color-on-secondary);
  --mg-bg-checkbox-checked: var(--mg-color-secondary);
  --mg-color-checkbox-label: var(--mg-color-on-background);
  --mg-color-checkbox-checked: var(--mg-color-on-background);
  --mg-checkbox-fw: 400;
  --mg-checkbox-ff: var(--mg-primary-ff);
  --mg-checkbox-fs: var(--mg-body2-fs);
  --mg-checkbox-icon-box-width: 24px;
  --mg-checkbox-icon-box-height: 24px;
  --mg-checkbox-icon-box-radius: 5px;
  --mg-checkbox-start-margin: 0 16px 0 0;
  --mg-checkbox-end-margin: 0 0 0 auto;

  /* Checkbox option tokens */
  --mg-bg-checkbox-option-container: var(--mg-color-primary-container);
  --mg-bg-checkbox-option-checked-container: var(--mg-color-primary-container);
  --mg-bg-checkbox-option: var(--mg-color-secondary-variant);
  --mg-bg-checkbox-option-checked: var(--mg-color-on-background);
  --mg-width-checkbox-option-outline: 1px;
  --mg-color-checkbox-option-outline: var(--mg-color-primary-outline);
  --mg-checkbox-option-radius: var(--rounded-radius-element);
  --mg-color-checkbox-option-label: var(--mg-color-on-background);
  --mg-color-checkbox-option-outline-checked: var(--mg-color-outline);
  --mg-color-checkbox-option-checked: var(--mg-color-on-background);
  --mg-checkbox-option-fw: 400;
  --mg-checkbox-option-ff: var(--mg-primary-ff);
  --mg-checkbox-option-fs-rectanle: 18px;
  --mg-checkbox-option-fs-square: 18px;
  --mg-checkbox-option-rectangle-gap: 8px;
  --mg-checkbox-option-square-gap: 12px;
  --mg-checkbox-option-padding-rectanle: 16px;
  --mg-checkbox-option-padding-square: 12px;
  --mg-checkbox-option-md-height-rectangle: 80px;
  --mg-checkbox-option-md-height-square: 156px;
  --mg-checkbox-option-md-width-square: 156px;
  --mg-checkbox-option-md-icon-rectangle: 24px;
  --mg-checkbox-option-md-icon-square: 72px;
  --mg-checkbox-option-icon-box-width: 24px;
  --mg-checkbox-option-icon-box-height: 24px;
  --mg-checkbox-option-icon-box-radius: 5px;
  --mg-checkbox-option-start-margin: 0 16px 0 16px;
  --mg-checkbox-option-end-margin: 0 0 0 auto;

  /* Container tokens */
  --mg-container-gutter-x: 24px;
  --mg-container-max-width: 540px;
  --mg-container-end-content-margin-bottom: 16px;

  /*  OnboardingHeader tokens*/
  --mg-onboardingheader-radius: var(--rounded-radius-4);
  --mg-onboardingheade-height: 2px;

  /*  HeadLine tokens*/
  --mg-headline-ff: var(--mg-primary-ff);
  --mg-headline-fs: var(--mg-body3-fs);
  --mg-headline-color: var(--mg-color-primary-container);
  --mg-bg-headline: var(--mg-color-primary);
  --mg-headline-padding: 4px 24px;
  --mg-headline-gg: var(--mg-grid-gap-8);

  /*  HeaderNavigation tokens*/
  --mg-headernavigation-padding: 0 16px;
  --mg-bg-headernavigation: transparent;
  --mg-headernavigation-gg: var(--mg-grid-gap-8);
  --mg-headernavigation-right-indent: 96px;

  /*  ProgressBar tokens*/
  --mg-bg-progressbar: var(--mg-color-primary-variant);
  --mg-bg-progressbar-fill: var(--mg-color-outline);
  --mg-progressbar-height: 2px;
  --mg-progressbar-radius: var(--rounded-radius-4);
  --mg-progressbar-transition: width 0.3s ease-in-out;
  --mg-progressbar-padding: 0 8px;

  /* Paywall tokens */
  --mg-paywall-card-description-fs: 20px;
  --mg-paywall-card-description-lh: 28px;
  --mg-paywall-card-text-fs: 14px;
  --mg-paywall-card-text-lh: 19px;
  --mg-paywall-card-price-unit-full-fs: 14px;
  --mg-paywall-card-price-unit-full-lh: 19px;
  --mg-paywall-card-title-float-fs: 24px;
  --mg-paywall-card-title-float-lh: 29px;
  --mg-paywall-card-current-price-large-fs: 48px;
  --mg-paywall-card-current-price-large-lh: 46px;
  --mg-paywall-card-discount-percent-fs: 14px;
  --mg-paywall-card-default-discount-fs: 14px;
  --mg-paywall-card-default-discount-fw: 400;
  --mg-paywall-card-default-discount-lh: 22px;
}
