.title,
.description {
  color: var(--mg-color-on-tertiary);
}

.title {
  margin-bottom: 16px;
}

.description {
  margin-bottom: 12px;
}

.labelClassName {
  --mg-radio-padding: 12px 16px;
  --mg-radio-md-height-rectangle: 72px;
  --mg-bg-radio-checked: var(--mg-color-on-secondary);
  --mg-color-radio-outline-checked: var(--mg-color-on-secondary);
}

.textClassName {
  font-weight: 700;
}

.subLabelClassName {
  font-size: var(--mg-body2-fs);
}

.headImage {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  z-index: -1;
  margin: -16px -24px 0;

  padding: 0 24px 34px;
  width: calc(100% + 48px);
  min-height: 232px;
  overflow: hidden;

  &::before,
  &::after {
    position: absolute;
    inset: 0 0 0 0;
    content: '';
  }

  &::before {
    z-index: 1;
    background: linear-gradient(
      180deg,
      rgba(135, 95, 244, 0) -19.76%,
      #875ff4 99.7%
    );
  }

  &::after {
    transform: rotate(-16deg);
    inset: -52px -24px;
    background: url('../../../../../../public/images/goal-jtbd-back.webp')
      no-repeat;
    background-size: 660px auto;
  }
}

.imageGrid {
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, 158px);
  justify-content: space-between;
  z-index: 2;
  grid-gap: 16px;

  > span {
    position: relative;
    height: 56px;
  }
}
