@use '../../styles/theme';

.container {
  color: inherit;
  text-align: center;

  a {
    text-decoration: underline;
    white-space: nowrap;

    &,
    &:visited,
    &:hover {
      color: inherit;
    }

    &:hover {
      text-decoration: none;
    }
  }
}
