.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin-bottom: 16px;
  border-radius: 16px;
  background: var(--white);
  padding: 12px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-bottom: 24px;
}

.tooltip {
  grid-gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 16px;
  background: var(--gray-light);
  padding: 8px;
}

.tooltipHeader {
  display: flex;
}

.tooltipBMI {
  margin-right: 6px;
  color: var(--accent-green);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
}

.tooltipCategory {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: var(--accent-green);
  padding: 2px 4px;
  color: var(--white);
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 140%;
}

.tooltipSubtitle {
  color: var(--primary-black);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}

.scaleContainer {
  grid-gap: 12px;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  width: 100%;
}

.linear {
  position: relative;
  align-self: stretch;
  margin: 0 10px;
  border-radius: 8px;
  background: linear-gradient(
    270deg,
    #ff5130 0%,
    #f5bc64 25.28%,
    #ffd770 50%,
    #b0f4a3 74.52%,
    #96d9fa 100%
  );
  height: 8px;
}

.bmiCircle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  border: 2px solid var(--gray-middle);
  border-radius: 50%;
  background: var(--white);
  width: 20px;
  height: 20px;
}

.bmiCount {
  position: absolute;
  top: -15px;
  transform: translate(-33%, -100%);
  box-sizing: border-box;
  border-radius: 8px;
  background-color: var(--local-black);
  padding: 6px 12px;
  min-width: 60px;
  color: var(--white);
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}
.triangle {
  position: absolute;
  top: calc(100% - 1px);
  left: 50%;
  margin-left: -16px;
  width: 32px;
  height: 16px;
  overflow: hidden;

  &:after {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    border-radius: 0 0 4px 0;
    background-color: var(--local-black);
    width: 16px;
    height: 16px;
    content: '';
  }
}

.scale {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.title,
.categoryContent,
.tooltipTitle {
  color: var(--primary-black);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
}

.subtitle,
.categoryTitle,
.scaleItem {
  color: var(--gray-dark);
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.subtitle,
.categoryTitle {
  font-size: 16px;
}

.tooltipTitle,
.tooltipSubtitle,
.scaleItem {
  font-size: 12px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.leftSide {
  display: flex;
  align-items: center;
}

.categoryContainer {
  grid-gap: 16px;
  display: flex;
  flex-direction: column;
}
