.menuItem {
  display: block;

  &,
  &:visited,
  &:hover {
    color: inherit;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.menuIconItem {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.menuIconItemIcon {
  margin-right: 12px;
  width: 22px;
  height: 22px;
}
