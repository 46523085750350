.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 95px;
  background-color: var(--white);
  padding: 0;
  width: 40px;
  height: 40px;

  &,
  &:hover,
  &:active,
  &:focus {
    border: none;
    background-color: var(--white);
  }
}
