.tooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-top: 32px;
  border-radius: 16px;
  background: var(--gray-light, #faf9ff);
  padding: 12px;
  width: 100%;
  max-width: 342px;

  &.purple {
    border: 1px solid var(--local-purple);
  }
  &.green {
    border: 1px solid var(--accent-green);
  }
  &.red {
    border: 1px solid var(--local-red);
  }
}

.title,
.subtitle {
  color: var(--primary-black);
  font-style: normal;
  font-size: 16px;
  line-height: 140%;
}

.title {
  font-weight: 700;
}

.subtitle {
  font-weight: 400;
}
