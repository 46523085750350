.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.optionsContainer {
  margin-top: 0;
  margin-bottom: 60px;
}

.submitButton {
  position: fixed;
  bottom: 16px;
  align-self: center;
  z-index: 1;
  margin-top: auto;
}

.skipButton {
  position: fixed;
  bottom: 88px;
  align-self: center;
  z-index: 1;
  margin-top: auto;
  background: var(--mg-bg-button-secondary);
}

.buttonContainer {
  grid-gap: 8px;
  display: flex;
  position: fixed;
  bottom: 16px;
  left: 50%;
  flex-direction: column;
  transform: translate(-50%, 0);
  padding-top: 30px;
  width: 100%;
}

.labelClassname {
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding: 0;
  overflow: hidden;

  & > span {
    flex: 1;
    text-align: left;
  }
}

.image {
  width: var(--mg-radio-md-icon-rectangle-max);
  height: var(--mg-radio-md-icon-rectangle-max);
}
