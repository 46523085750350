.tooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-top: 32px;
  border-radius: 16px;
  background: var(--gray-light);
  padding: 12px;
  width: 100%;
  max-width: 342px;

  &.purple {
    border: 1px solid var(--local-purple);

    .bmi {
      color: var(--local-purple);
    }

    .category {
      background: var(--local-purple);
    }
  }

  &.green {
    border: 1px solid var(--accent-green);

    .bmi {
      color: var(--accent-green);
    }

    .category {
      background: var(--accent-green);
    }
  }

  &.yellow {
    border: 1px solid var(--local-yellow);

    .bmi {
      color: var(--local-yellow);
    }

    .category {
      background: var(--local-yellow);
    }
  }

  &.orange {
    border: 1px solid var(--local-orange);

    .bmi {
      color: var(--local-orange);
    }

    .category {
      background: var(--local-orange);
    }
  }

  &.red {
    border: 1px solid var(--local-red);

    .bmi {
      color: var(--local-red);
    }

    .category {
      background: var(--local-red);
    }
  }

  &.tooltipOnFinalScreen {
    border: none;
  }
}

@media (max-width: 375px) {
  .tooltipOnFinalScreen {
    width: 310px;
  }
}

.header {
  display: flex;
}

.title,
.subTitle {
  color: var(--primary-black);
  font-style: normal;
  font-size: 16px;
  line-height: 140%;
}

.title {
  margin-right: 2px;
  font-weight: 700;
}

.subTitle {
  font-weight: 400;
}

.bmi {
  margin-right: 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
}

.category {
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
  padding: 2px 4px;
  color: var(--white);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
}
