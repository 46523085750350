.ageDisclaimer {
  display: flex;
  margin-top: 20px;
  grid-gap: 12px;
  border-radius: 16px;
  background: var(--gray-light);
  padding: 12px 16px;
}

.disclaimerTextContainer {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  font-size: 14px;
}
