.optionsContainer {
  padding-bottom: 90px;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  margin-left: 0;
}
