@use './theme';
@use './functions';

$primary: theme.$local-grey;
$secondary: theme.$local-bg;
$success: theme.$local-green;
$danger: theme.$local-red;
$light: theme.$white;
$dark: theme.$local-black;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'accent-1': theme.$local-red,
  'accent-2': theme.$local-purple,
);

$font-family-base: 'Inter', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.4;
$body-bg: theme.$white;

$input-padding-x: 16px;
$input-padding-y: 24px;
$input-font-size: 16px;
$input-line-height: 1.4;
$input-color: theme.$local-black;
$input-bg: inherit;
$input-border-color: theme.$local-middle-grey;
$input-border-radius: 16px;
$input-placeholder-color: theme.$local-grey;

$input-padding-x-sm: 8px;
$input-padding-y-sm: 8px;
$input-border-radius-sm: 8px;
$input-disabled-bg: theme.$local-bg;
$input-disabled-border-color: theme.$local-bg;

$enable-validation-icons: false;
$form-feedback-margin-top: 8px;
$form-feedback-font-size: 14px;
$form-feedback-invalid-color: theme.$local-red;
$form-feedback-valid-color: theme.$local-green;

$form-select-indicator-padding: 16px;
$form-select-bg-position: right 8px center;
$form-select-indicator: functions.inline-svg(
  '<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fill="#{theme.$local-grey}" d="M2.896,5.979c-0.195-0.195-0.195-0.512,0-0.707c0.195-0.195,0.512-0.195,0.707,0L2.896,5.979z M8,10.375 l0.354,0.354C8.26,10.822,8.133,10.875,8,10.875c-0.133,0-0.26-0.053-0.354-0.146L8,10.375z M12.396,5.271 c0.195-0.195,0.512-0.195,0.707,0c0.195,0.195,0.195,0.512,0,0.707L12.396,5.271z M3.604,5.271l4.75,4.75l-0.707,0.707l-4.75-4.75 L3.604,5.271z M7.646,10.021l4.75-4.75l0.707,0.707l-4.75,4.75L7.646,10.021z"/></svg>'
);
$form-select-bg-size: 16px 16px;
$form-select-border-radius: $input-border-radius;

$form-check-margin-bottom: 0;
$form-check-input-width: 1.5em;
$form-check-input-border-radius: 4px;
$form-check-input-bg: theme.$local-middle-grey;
$form-check-input-border: none;
$form-check-input-checked-bg-color: theme.$local-black;
$form-check-input-checked-bg-image: functions.inline-svg(
  '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18 8.96179L11.2336 16.1973C10.8561 16.6009 10.244 16.6009 9.86654 16.1973L6 12.0627L7.36703 10.6009L10.55 14.0046L16.633 7.5L18 8.96179Z" fill="#{theme.$white}"/></svg>'
);

$modal-backdrop-bg: rgba(0, 0, 0, 0.5);

$accordion-button-icon: functions.inline-svg(
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#{theme.$local-grey}"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>'
);
$accordion-button-active-icon: $accordion-button-icon;

$container-padding-x: 1.5rem;

$carousel-indicator-width: 6px;
$carousel-indicator-height: 4px;
$carousel-indicator-hit-area-height: 0;
$carousel-indicator-spacer: 3px;
$carousel-indicator-opacity: 1;
$carousel-indicator-active-bg: theme.$local-black;
$carousel-caption-width: 100%;
$carousel-caption-color: inherit;
$carousel-caption-padding-y: 0;
$carousel-caption-spacer: 0;
$carousel-transition-duration: 0.5s;

$enable-caret: false;
