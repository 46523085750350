.items {
  display: flex;
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
}

.item {
  display: flex;
  position: relative;
  width: calc(100% / 6 + 10px);
  height: 32px;

  &:not(:first-child) {
    margin-left: -10px;
    padding-left: 10px;

    &::before {
      position: absolute;
      top: 0;
      left: -30px;
      transform: rotate(45deg);
      box-shadow: -1px -1px 10px 0 rgba(0, 0, 0, 0.5);
      background: transparent;
      width: 32px;
      height: 32px;
      content: '';
    }
  }

  &:not(:last-child) {
    clip-path: polygon(
      0 0,
      calc(100% - 10px) 0,
      100% 50%,
      calc(100% - 10px) 100%,
      0 100%
    );
  }
}

@for $i from 1 through 6 {
  .item#{$i} {
    z-index: #{7 - $i};
    background: linear-gradient(to right, var(--start-color), var(--end-color));
  }
}

.item1 {
  --start-color: #fe4c5d;
  --end-color: #ffb37c;
}

.item2 {
  --start-color: #f3a275;
  --end-color: #f7e796;
}

.item3 {
  --start-color: #efdd8c;
  --end-color: #d1e5c1;
}

.item4 {
  --start-color: #cadbb2;
  --end-color: #aee2ea;
}

.item5 {
  --start-color: #a8d8dc;
  --end-color: #7bc9c2;
}

.item6 {
  --start-color: #76c0ba;
  --end-color: #2c9c73;
}

.dates {
  display: flex;
  justify-content: space-between;
  margin: 6px 0 10px;
  color: var(--color-pastel);
  font-size: var(--mg-body3-fs);
}

.itemsWithWeights {
  position: relative;
}

.weightValue {
  position: absolute;
  top: 5px;
  left: 12px;
  z-index: 20;
  color: var(--mg-color-on-primary);
  font-weight: 700;

  &:last-child {
    right: 12px;
    left: auto;
  }
}

.tooltip {
  position: relative;
  box-shadow: 0 0 16px 0 rgba(255, 63, 130, 0.1);
  border-radius: 8px;
  background: var(--mg-color-surface);
  padding: 8px 12px;
  font-size: 14px;
  text-align: center;

  &:before {
    position: absolute;
    top: -8px;
    right: 24px;
    border-right: 6px solid transparent;
    border-bottom: 8px solid var(--mg-color-surface);
    border-left: 6px solid transparent;
    width: 0;
    height: 0;
    content: '';
  }
}
