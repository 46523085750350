.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title, .boldTitle {
  margin-bottom: 24px;
  color: var(--primary-black);
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%;
  letter-spacing: 0.28px;
}

.boldTitle{
  color: var(--accent-pink);
}

.subtitle{
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%;
  letter-spacing: 0.2px;
  margin: 48px 0 24px;
  text-align: center;
}

.button {
  position: fixed;
  bottom: 16px;
  align-self: center;
}

.imageWrapper {
  position: relative;
  aspect-ratio: 301/266;
  width: 100%;
}

.subtext{
  margin-top: 12px;
  color: var(--color-pastel);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.resultsContainer{
  margin-top: 32px;
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 12px;
  border-radius: 16px;
  background: var(--local-bg);
}

.result{
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
}

.resultText, .completed{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.gridContainer {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  justify-items: center;
}

.item{
  position: relative;
  border-radius: 16px;
  width: 160px !important;
  height: 160px;
  overflow: hidden;
  display: flex !important;
  justify-content: center;
}

.sliderText{
  color: var(--white);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  position: absolute;
  bottom: 0;
  padding: 8px 12px;
  height: 68px;
  background: url('../../../../../../public/images/slider-preraywall-background.svg')
}

.image{
  display: block;
  position: relative;
  aspect-ratio: 160 / 160;
}