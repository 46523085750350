.submitButton {
  align-self: center;
}

.title {
  margin-bottom: 16px;
}

.description {
  margin-bottom: 24px;

  strong {
    color: var(--mg-color-tertiary);
  }
}

.subTitle {
  margin-bottom: 8px;
}

.image {
  margin-bottom: 24px;
  text-align: center;

  img {
    border-radius: 16px;
  }
}
