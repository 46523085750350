.tooltip{
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  justify-content: space-between;
}

.tooltipItem{
  display: flex;
  height: 98px;
  padding-left: 12px;
  align-items: center;
  grid-gap: 8px;
  border-radius: 16px;
}

.tooltipText{
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 8px;
}

.tooltipSubtext{
  display: flex;
  padding: 2px 8px;
  align-items: center;
  border-radius: 8px;
  background: var( --local-bg);
  width: fit-content;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}