@use '../../../../styles/utils';
@use '../../../../styles/bootstrap';

.imageContainer {
  align-self: center;
  margin: 8px 0 24px;
}

.title {
  margin: 0 0 16px;
  font-weight: 800;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
}

.description {
  margin-bottom: 24px;
  color: var(--local-grey);
  font-size: 14px;
  line-height: 140%;
  text-align: center;
}

@include bootstrap.media-breakpoint-down(sm) {
  .backdrop {
    @include utils.cover-area-under-modal(var(--white));
  }

  .container {
    display: flex;
    justify-content: flex-end;
    &:global(.fade) {
      transition: transform 0.3s ease-in-out;
      &:global(.show) {
        transform: none;
      }
      &:not(:global(.show)) {
        transform: translateY(100%);
        opacity: 1;
      }
      .modal {
        transform: none;
        transition: none;
      }
    }
  }

  .modal {
    align-items: flex-end;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    min-height: 0;
    :global(.modal-content) {
      border: 0 !important;
      border-radius: 0 !important;
    }
  }
}
