// Colors
$local-bg: #faf9ff;
$white: #ffffff;
$black: #000000;
$local-black: #161616;
$local-grey: #8d8d9c;
$local-middle-grey: #e7e7ec;
$dark-grey: #9a9b9e;
$second-grey: #f6f7f9;
$local-blue: #6764f2;
$local-second-blue: #6897d5;
$local-third-blue: #414d6a;
$local-light-blue: #ebebff;
$local-second-light-blue: #f0fafd;
$local-purple: #875ff4;
$light-blue: #e1f5fc;
$light-pink: #ffebfb;
$pink: #ffc9c6;
$local-red: #fe6170;
$local-green: #319683;
$local-pink: #ffefef;
$accent-gradient-start: #ff522c;
$accent-gradient-end: #ff3c8b;

// Fonts
$font-family-base: 'Inter', sans-serif;
$font-size-small: 14px;

// Sizes
$header-height: 118px;
$tablet-header-height: 96px;
$mobile-header-height: 72px;
$footer-height: 52px;
$panel-sider-width: 290px;
$mobile-only-container-max-width: 540px;
