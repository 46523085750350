.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 -20px 0 -20px;
  background: var(--local-light-blue);
}

.leftTitle,
.rightTitle {
  display: inline-block;
  margin-top: 16px;
  border-radius: 8px;
  padding: 4px 8px;
  width: fit-content;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
}

.leftTitle {
  background: var(--white);
}

.rightTitle {
  background: var(--accent-lilac);
  color: var(--white);
  text-align: center;
}

.subContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.afroTitle {
  margin-bottom: 16px;
}
