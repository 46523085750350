.host {
  background: linear-gradient(45deg, #06325e, #e73c7e, #23a6d5, #148fe8);;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  position: fixed;
  right: 4px;
  bottom: 4px;
  padding: 0;
  z-index: 9999;
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  transition: all 0.2s ease-in-out;
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.select {
  border-radius: 4px;
}

.expanded {
  width: calc(100% - 8px);
  padding: 8px;
}

.row {
  display: flex;
  grid-gap: 8px;
  overflow: auto;
}

.eventsSection {
  margin: 8px;
  max-height: 300px;
  overflow: auto;
}

.sectionTitle {
  color: var(--color-pastel);
  font-size: 17px;
}

.item:not(:last-child) {
  margin-bottom: 8px;
}

.featuresRow {
  margin-bottom: 12px;
}

.eventItem {
  border-bottom: 1px solid black;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 3px;
}

.eventName {
  color: #4169e1;
}

.eventParams {
  color: #228b22;
}

.eventDate {
  color: #004d4d;
}

.smallButton {
  padding: 2px 16px;
  --mg-button-md-min-height: 24px;
}
