.label {
  height: auto;

  & > span {
    display: flex;
    flex-direction: column;
    overflow: visible;
  }
}

.anyDiet {
  color: #737377;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
}
