.surface {
  margin: 24px 0;
  border-radius: 16px;
  background-color: var(--mg-color-surface);
  padding: 16px;
  text-align: left;
}

.title {
  margin-top: 32px;
  font-weight: 800;
  font-size: 28px;
  line-height: 140%;
  letter-spacing: 0.28px;
}

.aside {
  width: 100%;
}

.freeHost {
  display: flex;
  gap: 16px;
  margin-top: 16px;
  border-top: 1px solid var(--mg-color-primary-outline);
  padding-top: 16px;
}

.freeTitle {
  color: #ff485c;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
}

.description {
  font-size: 14px;
  line-height: 20px;
}

.imageWrapper {
  width: 56px;
  height: 56px;
}
