@import './bootstrap';

@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/utilities/api';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/accordion';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/offcanvas';

@import './bootstrap-global';

html {
  min-height: 100%;
}

body,
#__next {
  flex: 1;
}

html,
body,
#__next {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

mark {
  background: transparent;
}

#__next {
  justify-content: center;
}

:root {
  --local-bg: #faf9ff;
  --white: #ffffff;
  --black: #000000;
  --local-black: #161616;
  --local-grey: #8d8d9c;
  --local-middle-grey: #e7e7ec;
  --dark-grey: #9a9b9e;
  --second-grey: #f6f7f9;
  --light-grey: #F7F7F9;
  --local-blue: #6764f2;
  --local-second-blue: #6897d5;
  --local-third-blue: #414d6a;
  --local-light-blue: #ebebff;
  --local-second-light-blue: #f0fafd;
  --local-purple: #875ff4;
  --light-blue: #e1f5fc;
  --light-second-blue: #cde7ff;
  --light-pink: #ffebfb;
  --pink: #ffc9c6;
  --local-red: #fe6170;
  --local-green: #319683;
  --local-pink: #ffefef;
  --accent-gradient-start: #ff522c;
  --accent-gradient-end: #ff3c8b;
  --gray-light: #faf9ff;
  --primary-black: #161616;
  --gray-middle: #e7e7ec;
  --red-error: #ff3c3c;
  --accent-green: #2b9b73;
  --local-yellow: #ffd335;
  --local-orange: #f6a935;
  --gray-dark: #8d8d9c;
  --accent-pink: #ff3f82;
  --accent-lilac: #875ff4;
  --color-pastel: #737377;
  --color-red: #ff485c;
  --color-light-purple: #f1eeff;
  --accent-blue: #5974FF;

  // Fonts
  --font-size-small: 14px;

  // Sizes
  --header-height: 118px;
  --tablet-header-height: 96px;
  --mobile-header-height: 72px;
  --footer-height: 52px;
  --panel-sider-width: 290px;
  --mobile-only-container-max-width: 540px;
  color-scheme: only light;
}

:root {
  color: var(--local-black);
  font-size: $font-size-base;
  line-height: $line-height-base;
  font-family: $font-family-base;
}

a {
  text-decoration: none;

  &,
  &:visited,
  &:hover {
    color: inherit;
  }
}

a,
button,
input,
select,
textarea {
  touch-action: manipulation;
}

a,
button,
label,
input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
}

a,
button,
label {
  user-select: none;
}

button {
  border: none;
  background: none;
  padding: 0;
  overflow: visible;
  color: inherit;
  text-align: left;

  &::-moz-focus-inner {
    border: none;
    padding: 0;
  }
}

img {
  pointer-events: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
ul,
ol {
  margin: 0;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

h1 {
  font-weight: 800;
  font-size: 40px;
}

h2 {
  font-weight: 800;
  font-size: 28px;
}

@for $i from 1 through 6 {
  $backdrop-z-index: 1000 + (5 * $i);
  $content-z-index: $backdrop-z-index + 2;
  .modal-backdrop,
  .offcanvas-backdrop {
    &:nth-of-type(#{$i}) {
      z-index: $backdrop-z-index;
    }
  }
  div[role='dialog'][aria-modal='true']:nth-of-type(#{$i}) {
    z-index: $content-z-index;
  }
}

@include media-breakpoint-down(sm) {
  #__next {
    transition: transform 0.3s ease-in-out;
  }
  body[data-animated-modals-open]:not([data-animated-modals-open='0']) #__next {
    transform: none;
  }
}

.react-loading-skeleton {
  isolation: isolate;
}

@include media-breakpoint-down(md) {
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 20px;
  }
}
