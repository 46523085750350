@use './theme';
@use './bootstrap';

.nav-tabs {
  border: none;
  border-radius: 20px;
  background-color: theme.$local-bg;

  .nav-link {
    margin: 4px;
    border: none;
    border-radius: 16px;
    padding: 8px 24px;
    &,
    &:active,
    &:hover {
      color: theme.$local-grey;
    }

    &.active {
      &,
      &:hover,
      &:active {
        color: theme.$local-black;
        font-weight: bold;
      }
    }
  }
}

.carousel-indicators {
  [data-bs-target] {
    transition: width bootstrap.$carousel-transition-duration,
      background-color bootstrap.$carousel-transition-duration;
    margin: 0 4px;
    border-radius: 8px;
    background-color: theme.$local-middle-grey;

    &.active {
      background-color: theme.$local-black;
      width: 12px;
    }
  }
}
