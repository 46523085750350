@use '../../../../styles/bootstrap';

.container {
  width: 48px;
  height: 48px;
}

@include bootstrap.media-breakpoint-down(md) {
  .container {
    width: 40px;
    height: 40px;
  }
}
