.image {
  width: var(--mg-radio-md-icon-rectangle-max);
}

.label {
  padding: 0;
  overflow: hidden;
}

.labelHalfWidth {
  max-width: 156px;

  & > span {
    text-align: center;
    -webkit-line-clamp: 3;
  }
}
