.emailGroup {
  position: relative;
  margin-bottom: 16px;
  width: 100%;
}

.randomButton {
  position: absolute;
  top: 4px;
  right: 4px;
  animation: barber-stripe 1s linear infinite;
  background-image: repeating-linear-gradient(
    45deg,
    var(--accent-gradient-start) 0,
    var(--accent-gradient-start) 25px,
    var(--accent-gradient-end) 0,
    var(--accent-gradient-end) 50px
  );
  background-size: 50px 50px;
  padding: 4px 22px;
  width: 120px;
  height: 32px;
  --mg-button-md-min-height: 32px;
}

@keyframes barber-stripe {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 0;
  }
}

.errorText {
  margin-top: 8px;
  font-size: 0.875rem;
}

.verifyModal {
  background: linear-gradient(266.08deg, #ffcbc0 4.28%, #ffc5dc 87.63%);
}

.verifyModalContainer {
  padding: 0;
  text-align: center;
}

.verifyModalTitle {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 28px;
  line-height: 39px;
  font-family: var(--font-family-secondary), serif;
}

.verifyModalDescription {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 21px;
}

.verifyModalDescription {
}

.verifyModalWarning {
  margin-bottom: 18px;
}

.verifyModalButton {
}

//.salesImage {
//  margin: -18px -18px 50px -18px;
//  width: calc(100% + 36px);
//}
