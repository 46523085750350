@use './bootstrap';

@mixin modal-backdrop {
  backdrop-filter: blur(6px);
  &:global(.show) {
    opacity: 1;
  }
  @include bootstrap.media-breakpoint-down(sm) {
    backdrop-filter: none;
  }
}

@mixin safe-scrollable-horizontal-padding($value) {
  padding-left: $value;
  &::after {
    display: block;
    padding-right: $value;
    content: '';
  }
}

@mixin no-scrollbars {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin position-sticky {
  position: -webkit-sticky;
  @supports (position: sticky) {
    position: sticky;
  }
}

@mixin replace-backdrop-color($color, $transition-duration) {
  // Once the fade transition completes, setting the background to white.
  // This way, the backdrop blends with the modal background and is not visible during
  // scroll overshoot animation in iOS Safari.
  &:global(.show) {
    animation: force-backdrop-color 0s $transition-duration forwards;
  }
  @keyframes force-backdrop-color {
    100% {
      background-color: $color;
    }
  }
}

@mixin cover-area-under-modal($color) {
  // Covering the area underneath the iOS Safari address bar with a solid color,
  // so the content under the modal is not visible through the frosted glass effect
  &::after {
    position: fixed;
    top: min(100%, 100vh);
    right: 0;
    left: 0;
    background: $color;
    height: 100vh;
    content: '';
  }
}

@mixin safe-area-aware-padding-bottom($value) {
  $safe-area-aware-value: max(#{$value}, env(safe-area-inset-bottom));
  @supports (padding-bottom: $safe-area-aware-value) {
    padding-bottom: $safe-area-aware-value;
  }
}

@mixin if-safari-then-15-and-up {
  @supports (aspect-ratio: 1 / 1) {
    // Assuming the browser is Safari, it is Safari >= 15,
    // because 15.0 is the first version to support `aspect-ratio`.
    @content;
  }
}

@mixin safe-full-height($property: height, $subtract-value: 0) {
  // `100vh`/`100%` works differently in iOS Safari 15 and older versions, which causes issues.
  // Here Safari < 15 will take `height: 100%` and Safari >= 15 will take `height: 100vh`.
  // Other browsers will work correctly with any of the values.
  #{$property}: bootstrap.subtract(100%, $subtract-value);
  @include if-safari-then-15-and-up {
    #{$property}: bootstrap.subtract(100vh, $subtract-value);
  }
}

@mixin text-gradient {
  -webkit-text-fill-color: transparent;
  display: inline-block;
  -webkit-box-decoration-break: clone;
  background-image: linear-gradient(
    266.08deg,
    var(--accent-gradient-start) 4.28%,
    var(--accent-gradient-end) 87.63%
  );
  -webkit-background-clip: text;
}
