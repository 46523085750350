.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.contentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
}

.optionsContainer {
  grid-gap: 8px;
  //margin-top: 16px;
  //margin-bottom: 88px;
}

.yellowBodyBackground {
  background: #f8ae01;
}

.redBodyBackground {
  background: #ffc9c6;
}

.blueBodyBackground {
  background: #7a80b5;
}

.purpleBodyBackground {
  background: #a69ec5;
}

.whiteBodyBackground {
  background: #ffffff;
}

.cyanBodyBackground {
  background: #357196;
}

.magentaBodyBackground {
  background: #a34f7c;
}

.lightBlueBodyBackground {
  background: #ebebff;
}

.greenBodyBackground {
  background: #519b79;
}

.localBlueBodyBackground {
  background: #cde7ff;
}

.pinkBodyBackground {
  background: #fce9e6;
}

.lightPurpleBodyBackground {
  background: #f1eeff;
}

.purpleAnemoneBodyBackground {
  background: var(--mg-color-tertiary);
}

.customBg {
  background: var(--body-bg), linear-gradient(180deg, #ffe7e7 0%, #ff87b7 100%);
  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-blend-mode: overlay;

  &:after {
    position: absolute;
    z-index: -1;
    inset: 0 0 0 0;
    background: rgba(0, 0, 0, 0.05);
    content: '';
  }
}

.nextButton {
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translate(-50%, 0);
}
