.headerContainer {
  display: flex;
  flex-direction: column;
  margin: -16px 0 24px;
}

.secondContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 0;
  grid-gap: 24px;
}

.firstContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 24px;}

.thirdContainer{
  display: flex;
  flex-direction: column;
  margin: 32px -24px;
  padding: 32px 24px;
  background: #FCEAE7;
  grid-gap: 24px;
}

.title, .titleMark {
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%;
  letter-spacing: 0.28px;
}

.title{
  margin-top: 24px;
}

.titleMark{
  background: linear-gradient(108deg, #FF6D9B 19.18%, #9523FF 51.88%, #591599 76.05%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contentTitle{
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  letter-spacing: 0.22px;

  > b {
    color: var(--color-red);
  }
}

.subtitle{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 8px 0 24px;
}

.members {
  display: flex;
  align-items: center;
  margin: 8px -8px 16px;
  text-align: left;
  width: calc(100% + 20px);
  justify-content: center;
}

.membersHint {
  margin-left: 8px;
  font-size: 14px;
  line-height: 20px;
}

.bottomContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 24px;
  text-align: center;
  background: #FCEAE7;
  margin: -32px -24px -16px;
  padding: 32px 24px;
}

.button{
  margin: 0 auto;
}

.policy{
  font-size: 14px;
}