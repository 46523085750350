@use '../../../../styles/utils';
@use '../../../../styles/bootstrap';

.mobileHeader {
  display: none;
}

.headerCloseButton {
  width: 40px;
  height: 40px;
}

.closeButton {
  position: absolute;
  top: 0;
  right: -64px;
  width: 40px;
  height: 40px;
}

.closeIcon {
  width: 16px;
  height: 16px;
}

.title {
  margin: 8px 0 16px;
  font-weight: 800;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
}

.description {
  margin-bottom: 16px;
  color: var(--local-grey);
  font-size: 14px;
  line-height: 140%;
  text-align: center;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding-top: 24px;
}

@include bootstrap.media-breakpoint-down(sm) {
  .backdrop {
    backdrop-filter: none;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .container {
    $shadow-blur: 18px;
    -webkit-overflow-scrolling: touch;
    // See `utils.replace-backdrop-color` — the same logic is applied here,
    // otherwise the shadow might be visible at the bottom of the screen.
    animation: hide-box-shadow 0s 0.3s forwards;
    box-shadow: 0 0 $shadow-blur 0 rgba(0, 0, 0, 0.2);
    height: 100%;
    &:global(.fade) {
      transition: transform 0.3s ease-in-out;
      &:global(.show) {
        transform: none;
      }
      &:not(:global(.show)) {
        transform: translateX(bootstrap.add(100%, $shadow-blur));
        opacity: 1;
      }
      .modal {
        transform: none;
        transition: none;
      }
    }
    @keyframes hide-box-shadow {
      100% {
        box-shadow: none;
      }
    }
  }

  .modal {
    align-items: stretch;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    :global(.modal-content) {
      border: 0 !important;
      border-radius: 0 !important;
    }
  }

  .closeButton,
  .title {
    display: none;
  }

  .mobileHeader {
    @include utils.position-sticky;
    display: flex;
    top: 0;
    flex-shrink: 0;
    z-index: 1;
    margin: -18px -18px 16px;
  }

  .description {
    margin-bottom: 20px;
  }

  .footerContainer {
    padding-top: 18px;
  }
}
