@mixin progress-bar($radius) {
  border-radius: $radius;
  background-color: var(--local-middle-grey);
}

@mixin progress-value($color) {
  transition: width 0.3s ease-in-out;
  border-radius: inherit;
  background: $color;
}

.container {
  display: block;
  appearance: none;
  border: none;
  width: auto;
  height: 4px;

  @include progress-bar(--progress-radius);

  &::-webkit-progress-bar {
    @include progress-bar(var(--progress-radius));
  }

  &::-webkit-progress-value {
    @include progress-value(var(--color-value));
  }

  &::-moz-progress-bar {
    @include progress-value(var(--color-value));
  }
}
