.host {
  align-items: flex-end;
}

.leftImage {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
