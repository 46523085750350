.container {
  position: relative;
  box-shadow: 0 0 24px 0 rgba(255, 63, 130, 0.2);
  border-radius: 16px;
  background: var(--white);
  width: 100%;
  min-height: 355px;
  max-height: 479px;
}

.weightChangeLabel {
  position: absolute;
  top: 16px;
  color: #ee793a;
  font-weight: bold;
  font-size: 14px;
}

.weightLossLabel {
  right: 24px;
}

.weightGainLabel {
  left: 24px;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.title {
  color: var(--mg-color-on-surface-variant);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  & > b {
    margin-top: 6px;
    color: #161616;
  }
}

.tooltip {
  margin: 8px 16px 0;
  background: var(--mg-color-primary-container);
}

.contentClassName {
  font-size: 14px;
}

.subtext {
  position: absolute;
  bottom: 0;
  padding: 16px;
  color: #737377;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}
