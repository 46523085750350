.stepsContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

.stepOne, .stepTwo, .stepThree{
  display: flex;
  max-width: 540px;
  min-width: 100%;
  padding: 12px 16px;
  align-items: flex-start;
  grid-gap: 4px;
  border-radius: 16px;
  flex-direction: column;
}

.stepOne{
  transform: rotate(2deg);
  background: #EBEBFF;
  & .name{
    color: #7F51FD;
  }
}

.stepTwo{
  transform: rotate(-2deg);
  background: #FFE0FB;
  & .name{
    color: #E039CC;
  }
}

.stepThree{
  transform: rotate(2deg);
  background: #FFEBDB;
  flex-direction: row;position: relative;
  & .name{
    color: #F57D1D;
  }
  box-shadow: 0 1.892px 11.354px 0 rgba(0, 0, 0, 0.25);
}

.name{
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.18px;
}

.text{
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.thirdContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 4px;
}

.image{
  position: absolute;
  right: -10px;
  bottom: -10px;
}