.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title, .boldTitle {
  margin-bottom: 24px;
  color: var(--primary-black);
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 120%;
  letter-spacing: 0.22px;
  text-align: center;
}

.boldTitle{
  color: var(--accent-pink);
}

.submitButton {
  align-self: center;
  position: fixed;
  bottom: 16px;
}

.tooltipContainer, .stepsContainer{
  margin: 0 -24px;
  background: var(--white);
  padding: 32px 24px;
  width: calc(100% + 48px);
}
.tooltipContainer,{
  margin-top: 24px;
}

.stepsContainer{
  padding-bottom: 80px;
  margin-bottom: -16px;
}

.tooltipTitle{
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%;
  letter-spacing: 0.2px;
  text-align: center;
  margin-bottom: 24px;

}

.steps{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
}

.step{
  display: flex;
  align-items: flex-start;
  grid-gap: 12px;
}

.stepText{
  font-size: 16px;
  font-style: normal;
  line-height: 140%;
}