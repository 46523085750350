.subtitle{
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%;
  letter-spacing: 0.2px;
  margin: 48px 0 24px;
  text-align: center;
}

.completed{
  margin-top: -16px;
  text-align: center;
  margin-bottom: 24px;
}

.stepsContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

.stepOne, .stepTwo, .stepThree{
  display: flex;
  max-width: 540px;
  min-width: 335px;
  padding: 12px 16px;
  align-items: flex-start;
  grid-gap: 4px;
  border-radius: 16px;
  flex-direction: column;
  min-height: 90px;
}

.stepOne{
  transform: rotate(2deg);
  background: #E9F3FF;
  & .name{
    color: #2688FB;
  }
}

.stepTwo{
  transform: rotate(-2deg);
  background: #FFF8E6;
  & .name{
   color: #EEAD03;
  }
}

.stepThree{
  transform: rotate(2deg);
  background: #FFEBDB;
  flex-direction: row;position: relative;
  & .name{
    color: #FF485C;
  }
}

.name{
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.18px;
}

.text{
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.thirdContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 4px;
}

.image{
  position: absolute;
  right: 0;
  bottom: 0;
}