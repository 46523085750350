.item {
  display: flex;
  gap: 8px;

  .icon,
  img {
    display: block;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
