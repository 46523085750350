.container {
  display: flex;
  position: relative;
  flex-direction: column;
}

.secondContainer {
  position: absolute;
  bottom: 16px;
  width: 100%;
  grid-gap: 16px;
  display: flex;
  justify-content: center;
  padding: 0 16px;
}

.side {
  grid-gap: 8px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: var(--mg-color-surface);
  padding: 8px;

  &:last-child {
    background-color: var(--mg-color-tertiary);
    color: var(--mg-color-on-tertiary);

    .description {
      color: var(--mg-color-on-tertiary);
    }
  }
}

.titleBlock,
.description {
  font-style: normal;
  line-height: 140%;
  text-align: center;
}

.titleBlock {
  font-weight: 700;
  font-size: 16px;
}

.description {
  color: var(--color-pastel);
  font-weight: 400;
  font-size: 14px;
}

.subtitle {
  margin-bottom: 0 !important;
  color: var(--gray-dark) !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 140%;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
