@use '../../../../../styles/utils.scss';

.lineAnimation {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: lineDraw 3.5s forwards;
}

@keyframes lineDraw {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.labelAnimation {
  opacity: 0;
  animation: labelReveal 2.5s forwards;
}

@keyframes labelReveal {
  100% {
    opacity: 1;
  }
}

.circleAnimation {
  animation: drawCircle 0.5s forwards;
}

@keyframes drawCircle {
  0% {
    r: 0;
  }
  100% {
    r: 10;
  }
}

.subtext {
  position: absolute;
  margin-top: -12px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background: white;
  padding: 16px;
  color: #737377;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}
