.container{
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
}

.step{
    display: flex;
    flex-direction: row;
    grid-gap: 12px;
}