@use 'src/styles/bootstrap';

.sliderContainer {
    background: transparent;
  :global(.slick-prev), :global(.slick-next) {
    top: 33.6%;
    border-radius: 50%;
    z-index: 2;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
  }
  :global(.slick-prev) {
    left: 16px;
  }
  :global(.slick-next) {
    right: 16px;
  }
  :global(.slick-prev):before, :global(.slick-next):before {
    content: none;
  }
}

.slide {
  padding-bottom: 16px;
  overflow: hidden;
  border-radius: 16px;
  border: 1px solid #E5E5E5;
  background: white;
}

.beforeAfterContainer {
  display: flex;
  justify-content: center;
  position: relative;
  grid-gap: 1px;
}

.imageWrapper {
  position: relative;
  aspect-ratio: 167/335;
  flex: 1 1;
  width: 100%;
  max-height: 400px;
  height: 100%;
}

.label {
  width: calc(100% - 16px);
  padding: 4px 8px;
  text-align: center;
  border-radius: 8px;
  position: absolute;
  bottom: 8px;
  left: 8px;
  right: 8px;
  background: white;
  font-weight: bold;
  em {
    font-style: normal;
    color: #FF485C;
  }
}

.description {
  text-align: left;
  padding: 0 16px;
  font-size: clamp(14px, 3vw + 1px, 17px);
  text-overflow: ellipsis;
  margin-top: 24px;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fullDescription {
  text-align: left;
  padding: 0 16px;
  font-size: 14px;
  margin-top: 24px;
}

.readMore {
  color: #737377;
  cursor: pointer;
  margin-top: 16px;
  text-align: center;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  border: none;
  width: 40px;
  z-index: 10;
  height: 40px;
}

.modalContainer {
  :global(.modal-content) {
    padding: 0 0 24px 0;
    background: white;
    border: 1px solid #E5E5E5 !important;
    overflow: hidden;
  }
  .slide {
    border: none;
    padding-bottom: 0;
  }
}

@include bootstrap.media-breakpoint-down(sm) {
  .modalContainer {
    :global(.modal-content) {
      margin: auto 15px;
      border-radius: 16px !important;
      overflow: hidden;
    }
  }
}
