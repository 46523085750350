@use '../../../../../styles/bootstrap';

.container {
  position: absolute;
  margin: -16px -24px;
  background: var(--light-second-blue);
  padding: 24px;
  width: 100%;
  min-height: 100vh;
}

.containerCircle {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 16px;

  svg {
    circle {
      &:first-child {
        stroke: var(--black);
        opacity: 0.2;
      }
      &:nth-child(2) {
        stroke: url(#paint0_linear_5520_31945);
      }
    }
  }
  h1 {
    -webkit-text-fill-color: var(--black);
    color: var(--white);
  }
}

.title {
  color: var(--black);
  font-weight: 800;
  font-size: 28px;
  text-align: center;
}

.subtitle {
  margin-bottom: 60px;
  color: var(--primary-black);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
}

.rating {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  margin: 15px 0;
}

.ratingContainer {
  grid-gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.ratingText {
  color: var(--primary-black);
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.2px;
}

.content {
  color: var(--primary-black);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
}

.modalContent {
  backdrop-filter: none;
  background-color: rgba(0, 0, 0, 0.1);

  :global(.modal-content) {
    display: flex;
    flex-direction: column;
    margin: auto;
    border-radius: 20px !important;
    background: var(--white);
    padding: 16px 16px 16px 16px;
    width: 327px;
    height: auto;
  }
  :global(.modal_title) {
    display: flex;
  }
}

@include bootstrap.media-breakpoint-down(sm) {
  .backdrop {
    backdrop-filter: blur(8px) !important;
    background: rgba(0, 0, 0, 0.3) !important;
    font-size: 20px;
  }
}

.modalTitle {
  margin-bottom: 8px;
  color: var(--primary-black);
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.2px;
  text-align: center;
}

.modalSubtitle {
  margin-bottom: 20px;
  color: var(--gray-dark);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
}

.modalButton {
  grid-gap: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  border: 1px solid var(--gray-middle);
  border-radius: 16px;
  background: var(--white);
  padding: 20px 16px;
  height: 72px;
}
