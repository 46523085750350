.headerButton {
  z-index: 1;
  border: none;
  background-color: transparent !important; //build style priority problems
  width: 36px !important; //build style priority problems
  min-width: 36px;
  height: 36px !important; //build style priority problems
  min-height: 36px;
  &:hover,
  &:focus {
    background-color: var(
      --color-white
    ) !important; //build style priority problems
  }
}

.headerButtonUiKit {
  display: flex;
  width: 24px;
  height: 24px;
}

.navigationButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.hiddenTopBar {
  display: none;
}
