@use '../../../../styles/utils.scss';

.contentContainer {
  padding-top: 8px;
}

.progressContainer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.progressPercentage {
  @include utils.text-gradient;
  position: absolute;
  transition: all 0.3s ease;
  font-weight: 800;
  font-size: 40px;
  font-variant-numeric: tabular-nums;
}

.progress {
  display: block;
  transform: rotateZ(-90deg);
  width: 199px;
  height: 199px;
}

.progressBar,
.progressValue {
  fill: none;
  stroke-width: 12;
  cx: 99.5;
  cy: 99.5;
  transition: all 0.3s ease;
}

.progressBar {
  stroke: var(--local-middle-grey);
}

.progressValue {
  stroke: url(#gradient-progress);
  stroke-linecap: round;
}
