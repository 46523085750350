.container {
  color: var(--local-grey);
  text-align: center;

  a {
    text-decoration: underline;
    text-underline-offset: 2px;
    &:hover {
      text-decoration: none;
    }
  }
}

.containerWithWordSpacing {
  word-spacing: 8px;
  a {
    white-space: nowrap;
    word-spacing: normal;
  }
}
