.container {
  display: flex;
  justify-content: center;
}

.dot {
  animation: 1s flashing linear infinite alternate;
  border-radius: 5px;
  background-color: currentColor;
  width: 10px;
  height: 10px;

  &:not(:last-child) {
    margin-right: 5px;
  }

  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      animation-delay: ($i - 1) * 0.5s;
    }
  }
}

@keyframes flashing {
  0% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0.3;
  }
}
