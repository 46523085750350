.mainContainer {
  display: flex;
  position: relative;
  top: -40px;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-top: -32px;
  padding: 16px 24px 16px 24px;
}

@media (min-width: 760px) {
  .mainContainer {
    width: 744px !important;
  }
}

.header {
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 120%;
  letter-spacing: 0.22px;
  text-align: center;
}

.subtitle {
  display: flex;
  color: var(--mg-color-on-surface-variant);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  grid-gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.container {
  grid-gap: 16px;
  display: flex;
  flex-direction: column;
}

.label {
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
  width: 156px;
  height: 186px;
  overflow: hidden;
}

.image {
  margin-right: 0;

  & > span {
    max-width: 156px;
  }
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  background: var(--mg-color-outline);
  width: 100%;
  height: 100%;
  color: var(--mg-color-on-primary);
  text-align: center;
}
