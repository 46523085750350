.host {
  justify-content: end;
  z-index: 100;
  padding: 14px 24px;
}

.label {
  max-width: 156px;
  & > span {
    text-align: center;
  }
}

.container {
  flex: 1;
  margin: -14px -24px -16px -24px;
}

.optionHost {
  z-index: 1000;
}
