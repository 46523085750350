.container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border: 0;
  border-radius: 20px;
  padding: 0 16px;
  width: 327px;
  height: 64px;
  font-weight: bold;
}

.containerPrimary {
  &,
  &:visited,
  &:active,
  &:focus,
  &:hover {
    background: linear-gradient(
      266.08deg,
      var(--accent-gradient-start) 4.28%,
      var(--accent-gradient-end) 87.63%
    );
    color: var(--white);
  }

  &:disabled {
    opacity: 1;
    background: var(
      --gradient-disabled,
      linear-gradient(266deg, #ffcbc0 4.28%, #ffc5dc 87.63%)
    );
    color: var(--white);
  }
}

.containerSecondary {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    border: 0;
    background-color: var(--mg-color-primary-outline) !important;
    color: var(--local-black);
  }
}

.containerTertiary {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    border: 0;
    background-color: var(--local-purple);
    padding: 8px 48px;
    width: 136px;
    height: 38px;
    color: var(--white);
  }
}

.containerQuaternary {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    border: 0;
    background: var(--gray-light);
    color: var(--local-black);
  }
}

.containerLink {
  &,
  &:visited,
  &:hover,
  &:focus,
  &:active {
    border: 0;
    background: none;
    padding: 8px 40px;
    width: auto;
    height: auto;
    color: #817b6f;
    font-weight: normal;
    text-decoration: underline;
  }
}

.icon {
  margin-right: 12px;
}

.sm {
  width: auto;
  height: 24px;
}

.lg {
  height: 72px;
}
