.host {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.container {
  display: flex;
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px 24px 0 0;
  background-color: var(--white);
  padding: 24px;
  width: 100%;
}

.content {
  margin: 0 auto;
  max-width: 327px;
  text-align: center;
}

.title {
  margin-bottom: 12px;
}

.text {
  margin-bottom: 32px;
  color: #737377;
}

.link {
  display: inline-flex;
  margin-top: 16px;
  color: #737377;
  text-decoration: underline;
}
